<template>
  <el-form
    :inline="inline === 'true'"
    :model="form"
    ref="formRef"
    class="jlForm"
    :label-width="inline === 'true' ? null : 'auto'" 
  >
    <template v-for="(item, index) in columns" :key="index">
      <el-form-item
        :label="item.label ? $t('i18n.' + item.label) + '：' : ''"
        :rules="item.rules"
        :prop="item.prop"
        v-if="item.show !== false"
      >
        <!-- 展示框 -->
        <span v-if="item.format" v-html="item.format(form[item.prop])"></span>

        <!-- 选择框 -->
        <expand-select
          v-else-if="item.type == 'select'"
          filterable
          clearable
          :multiple="item.multiple"
          :options="item"
          :i18n="$t"
          :disabled="item.readonly"
          v-model="form[item.prop]"
          :value="item.value"
          @change="item.change"
        ></expand-select>

        <!-- 级联选择器 -->
        <el-cascader
          v-else-if="item.type == 'cascader'"
          v-model="form[item.prop]"
          :options="item.data"
          :disabled="item.readonly"
          :props="{ checkStrictly: item.checkStrictly }"
          @change="item.change"
        ></el-cascader>

        <!-- 远程搜索 -->
        <expand-select
          v-else-if="item.type == 'remote' || item.type == 'remotes'"
          filterable
          remote
          :multiple="item.multiple"
          reserve-keyword
          :options="item"
          :placeholder="$t('i18n.anySearch')"
          v-model="form[item.prop]"
          :value="item.value"
          :loading="item.loading"
          :disabled="item.readonly"
          @change="item.change"
          :remote-method="item.remoteMethod"
        ></expand-select>

        <!-- 上传框 -->
        <el-upload
          v-else-if="item.type == 'upload' && item.readonly != true"
          class="upload-demo"
          ref="item.prop"
          :limit="item.limit"
          :action="$defined.uploadUrl + item.action"
          :on-success="
            (response, file, fileList) => {
              uploadSuccess(response, file, fileList, item.prop);
            }
          "
          :on-progress="
            (event, file, fileList) => {
              uploadProgress(item.prop);
            }
          "
        >
          <el-button
            size="small"
            type="primary"
            :loading="upload[item.prop] === 'upload'"
          >
            {{ $t("i18n.uploadAttachment") }}
          </el-button>
          <template #tip>
            <div class="el-upload__tip">{{ item.tip }}</div>
          </template>
        </el-upload>
        <div v-else-if="item.type == 'upload' && item.readonly == true">
          <template v-for="file in item.value" :key="file">
            <el-image
              v-if="checkIsImg(file.url)"
              style="width: 120px; height: 34px"
              :src="$defined.imgUrl + file.url"
              :preview-src-list="setImgList(item.value)"
            ></el-image>
            <span v-else-if="file.url == 'null'">{{ $t("i18n.none") }}</span>
            <a v-else :href="$defined.imgUrl + file.url">{{ file.name }}</a>
          </template>
        </div>

        <!-- 多选框 -->
        <el-checkbox-group
          v-else-if="item.type == 'checkbox' && item.data instanceof Array"
          v-model="form[item.prop]"
        >
          <el-checkbox
            v-for="e in item.data"
            :key="e"
            :label="e[item.props.value]"
            :disabled="item.readonly"
          >
            {{ e[item.props.label] }}
          </el-checkbox>
        </el-checkbox-group>
        <el-checkbox
          v-else-if="item.type == 'checkbox'"
          v-model="form[item.prop]"
          :label="$t('i18n.' + item.label)"
          :disabled="item.readonly"
          border
        ></el-checkbox>

        <!-- 密码框 -->
        <el-input
          v-else-if="item.type == 'password'"
          show-password
          v-model="form[item.prop]"
          autocomplete="off"
          auto-complete="new-password"
          @change="item.change"
          :placeholder="item.placeholder"
          :disabled="item.readonly"
        ></el-input>

        <!-- 只读 -->
        <el-input
          v-else-if="item.readonly == true"
          v-model="form[item.prop]"
          :value="item.value"
          autocomplete="off"
          disabled="true"
        ></el-input>

        <!-- 输入框 -->
        <el-input
          v-else-if="item.type == 'input'"
          v-model="form[item.prop]"
          autocomplete="off"
          @change="item.change"
          :placeholder="item.placeholder"
          :disabled="item.readonly"
        ></el-input>

        <!-- 文本框 -->
        <el-input
          v-else-if="item.type == 'textarea'"
          type="textarea"
          v-model="form[item.prop]"
          autocomplete="off"
          :rows="item.areaRows"
          @change="item.change"
          :disabled="item.readonly"
        ></el-input>

        <!-- 滑块 -->
        <el-slider
          v-else-if="item.type == 'slider'"
          v-model="form[item.prop]"
          range
          show-stops
          :min="item.min"
          :max="item.max"
          :marks="item.marks"
        ></el-slider>

        <!-- 开关 -->
        <el-switch
          v-else-if="item.type == 'switch'"
          v-model="form[item.prop]"
          :active-text="$t('i18n.' + item.text.aText)"
          :inactive-text="$t('i18n.' + item.text.iText)"
          :active-value="item.text.aVal"
          :inactive-value="item.text.iVal"
          :disabled="item.readonly"
          @change="item.change"
        ></el-switch>

        <!-- 按钮 -->
        <el-button
          type="primary"
          v-else-if="item.type == 'button'"
          @click="item.method"
        >
          {{ $t("i18n." + item.buttonLabel) }}
        </el-button>

        <!-- 日期框 -->
        <el-date-picker
          v-else-if="item.type == 'date' || item.type == 'year'"
          v-model="form[item.prop]"
          :type="item.type"
          placeholder="选择日期"
          @change="item.change"
          :disabled="item.readonly"
        ></el-date-picker>

        <!-- 日期范围 -->
        <el-date-picker
          v-else-if="item.type == 'dateRange'"
          v-model="form[item.prop]"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :disabled="item.readonly"
        ></el-date-picker>

        <!-- 时间范围 -->
        <el-time-picker
          v-else-if="item.type == 'timeRange'"
          is-range
          v-model="form[item.prop]"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
        ></el-time-picker>
      </el-form-item>
    </template>
    <el-form-item
      v-if="option && option.length > 0"
      :class="{ 'form-button': inline !== 'true' }"
    >
      <template v-for="(btn, index) in option" :key="index">
        <el-button
          v-if="btn.show"
          :class="'el-button--' + btn.class"
          @click="btn.method(form, validateForm())"
          round
          v-preventReClick
        >
          {{ $t("i18n." + btn.label) }}
        </el-button>
      </template>
    </el-form-item>
    <el-form-item
      v-else-if="columns.filter((_x) => _x.readonly !== true).length > 0"
      :class="{ 'form-button': inline !== 'true' }"
    >
      <el-button type="primary" @click="onSubmit" round v-preventReClick>
        {{ $t("i18n." + confirm) }}
      </el-button>
      <el-button class="el-button--reset" @click="resetForm" round>
        {{ $t("i18n." + cancel) }}
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";
import { reactive, toRefs, ref, h, getCurrentInstance, watch } from "vue";
export default {
  name: "form",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    option: {
      type: Array,
      default: () => [],
    },
    inline: {
      type: String,
      default: () => "false",
    },
    confirm: {
      type: String,
      default: () => "confirm",
    },
    cancel: {
      type: String,
      default: () => "cancel",
    },
  },
  emits: ["onSubmit", "resetForm"],
  components: {
    expandSelect: {
      functional: true,
      key: "expandSelect",
      slot: "select-of-slot",
      props: {
        options: {
          type: Object,
          default: () => {},
        },
        i18n: {
          type: Function,
          default: () => {},
        },
      },
      render: (f, p, ctx) => {
        return h(
          ElSelect,
          {
            valueKey: "id",
          },
          ctx.options.data.map((op) =>{
            return h(ElOption, {
              key: op[ctx.options.props.label],
              label:
                ctx.options.props.label === "locale" || ctx.options.props.label === "code"
                  ? ctx.i18n("i18n." + op[ctx.options.props.label])
                  : op[ctx.options.props.label],
              value: ctx.options.props.value ? op[ctx.options.props.value] : op,
              disabled : !!op["disabled"]
            })}
          )
        );
      },
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      form: {},
      uploadArray: [],
      upload: {},
    });

    const onSubmit = () => {
      for (let key in state.upload) {
        if (!formRef.value.model[key] && state.upload[key]) {
          proxy.$defined.tip("文件正在上传，请稍后提交！");
          return;
        }
      }
      formRef.value.validate((valid) => {
        if (valid) {
          emit("onSubmit", state.form);
        }
      });
    };

    const validateForm = () => {
      return formRef.value.validate();
    };

    const uploadSuccess = (res, file, fileList, key) => {
      // on-change 传自定义参数
      if (res.success) {
        proxy.$defined.tip("文件上传成功！", "success");
        state.form[key] = res.data.fileName;
        delete state.upload[key];
      } else {
        proxy.$defined.tip(res.error);
      }
    };

    const uploadProgress = (key) => {
      state.upload[key] = "upload";
    };

    const resetForm = () => {
      formRef.value.resetFields();
      emit("resetForm");
    };

    watch(
      () => props.columns,
      () => {
        //通过一个函数返回要监听的属性
        setDefault();
      },
      {
        deep: true,
      }
    );

    const setDefault = () => {
      props.columns.map((item) => {
        if (item.isUpdate) {
          state.form[item.prop] = item.value === undefined ? "" : item.value;
        }
        if (item.uploadUrl && item.data.length === 0) {
          proxy.$api.common.getLineageByCode(item.uploadUrl).then((res) => {
            item.data = res.data.lineages;
          });
        }
      });
    };

    const checkIsImg = (url) => {
      return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
    };

    const setImgList = (list) => {
      let arr = [];
      list.map((item) => {
        arr.push(proxy.$defined.imgUrl + item.url);
      });
      return arr;
    };

    setDefault();

    return {
      ...toRefs(state),
      formRef,
      onSubmit,
      resetForm,
      validateForm,
      uploadSuccess,
      uploadProgress,
      checkIsImg,
      setImgList,
    };
  },
};
</script>
<style lang="scss">
.jlForm {
  .form-button {
    margin: 50px 0 0;
    text-align: right;
  }
  .el-cascader {
    width: 100%;
  }
  .el-slider {
    padding: 0 10px;
    .el-slider__marks {
      .el-slider__marks-text {
        margin-top: 5px;
        &:last-child {
          width: 17px;
        }
      }
    }
  }
  .upload-demo {
    .el-progress-bar,
    .el-progress__text {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .jlForm {
    .form-button {
      margin: 20px 0 0;
      text-align: center;
      .el-form-item__content {
        width: 100%;
        margin: 0 !important;
      }
    }
  }
}
</style>
